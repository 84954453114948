// import {Environment} from "./environment";
export const defaultEnvironmentProd = {
    // loginRealm: realm ?? "master",
    // clientId: "security-admin-console",
    authServerUrl: "https://sso-keycloak.orenda.vn",
    authUrl: "https://sso-keycloak.orenda.vn",
    // consoleBaseUrl: "/admin/master/console/",
    // resourceUrl: ".",
    // masterRealm: "master",
    // resourceVersion: "unknown",
    // logo: "/logo.svg",
    // logoUrl: "",
};
