import React, {CSSProperties} from 'react';
import environment from "../environment";

export type OrdIconSvgType = {
    width?: number;
    fileName?: string;
    style?: CSSProperties | undefined;
    imgStyle?: CSSProperties | undefined;
}
const OrdIconSvg = (zInput: OrdIconSvgType) => {
    const link = "/ord-icons/" + zInput.fileName;
    return (
        <div style={{
            textAlign: "center",
            ...zInput.style
        }}>
            <img style={zInput.imgStyle} src={environment.resourceUrl + link} alt={zInput.fileName}/>
        </div>
    );
}
export default OrdIconSvg;
