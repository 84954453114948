import {
    Divider,
    Nav,
    NavGroup,
    NavItem,
    NavList,
    PageSidebar,
} from "@patternfly/react-core";
import React, {FormEvent} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useMatch, useNavigate} from "react-router-dom";

import {RealmSelector} from "./components/realm-selector/RealmSelector";
import {useAccess} from "./context/access/Access";
import {useRealm} from "./context/realm-context/RealmContext";
import {AddRealmRoute} from "./realm/routes/AddRealm";
import {routes} from "./routes";

import "./page-nav.css";
import OrdIconSvg from "./utils/OrdIconSvg";

type LeftNavProps = { title: string; path: string; iconFileName?: string };

const LeftNav = ({title, path, iconFileName}: LeftNavProps) => {
    const {t} = useTranslation("common");
    const {hasAccess} = useAccess();
    const {realm} = useRealm();
    const route = routes.find(
        (route) => route.path.replace(/\/:.+?(\?|(?:(?!\/).)*|$)/g, "") === path,
    );

    const accessAllowed =
        route &&
        (route.handle.access instanceof Array
            ? hasAccess(...route.handle.access)
            : hasAccess(route.handle.access));

    if (!accessAllowed) {
        return null;
    }

    return (
        <li>
            <NavLink
                id={"nav-item" + path.replace("/", "-")}
                to={`/${realm}${path}`}
                className={({isActive}) =>
                    `pf-c-nav__link${isActive ? " pf-m-current" : ""}`
                }
            >
                {iconFileName ?
                    <OrdIconSvg fileName={'menus/' + iconFileName}
                                style={{height: '24px', width: '24px', marginRight: '5px'}}/>
                    : ''
                }
                {t(title)}
            </NavLink>
        </li>
    );
};

export const PageNav = () => {
    const {t} = useTranslation("common");
    const {hasSomeAccess} = useAccess();

    const navigate = useNavigate();

    type SelectedItem = {
        groupId: number | string;
        itemId: number | string;
        to: string;
        event: FormEvent<HTMLInputElement>;
    };

    const onSelect = (item: SelectedItem) => {
        navigate(item.to);
        item.event.preventDefault();
    };

    const showManage = hasSomeAccess(
        "view-realm",
        "query-groups",
        "query-users",
        "query-clients",
        "view-events",
    );

    const showConfigure = hasSomeAccess(
        "view-realm",
        "query-clients",
        "view-identity-providers",
    );

    const isOnAddRealm = !!useMatch(AddRealmRoute.path);

    return (
        <PageSidebar
            className="keycloak__page_nav__nav"
            nav={
                <Nav onSelect={onSelect}>
                    {/*<NavList>*/}
                    {/*  <NavItem className="keycloak__page_nav__nav_item__realm-selector">*/}
                    {/*    <RealmSelector />*/}
                    {/*  </NavItem>*/}
                    {/*</NavList>*/}
                    {/*<Divider />*/}
                    {showManage && !isOnAddRealm && (
                        <NavGroup aria-label={t("manage")} >
                            {/*title={t("manage")}*/}
                            <LeftNav title="clients" path="/clients" iconFileName="UserCheckRounded.png"/>
                            <LeftNav title="clientScopes" path="/client-scopes" iconFileName="Translation.png"/>
                            <LeftNav title="realmRoles" path="/roles" iconFileName="Widget.png"/>
                            <LeftNav title="users" path="/users" iconFileName="User_Rounded.png"/>
                            <LeftNav title="groups" path="/groups" iconFileName="Users_Group.png"/>
                            <LeftNav title="sessions" path="/sessions" iconFileName="Code.png"/>
                            <LeftNav title="events" path="/events" iconFileName="Events.png"/>
                        </NavGroup>
                    )}
                    <Divider/>
                    {showConfigure && !isOnAddRealm && (
                        <NavGroup aria-label={t("configure")} >
                            {/*title={t("configure")}*/}
                            <LeftNav title="realmSettings" path="/realm-settings" iconFileName="Settings.png"/>
                            <LeftNav title="authentication" path="/authentication" iconFileName="Shield_User.png"/>
                            <LeftNav title="identityProviders" path="/identity-providers" iconFileName="Eye_Scan.png"/>
                            <LeftNav title="userFederation" path="/user-federation" iconFileName="Link.png"/>
                        </NavGroup>
                    )}
                </Nav>
            }
        />
    );
};
