import {
    Avatar,
    Brand,
    Dropdown,
    DropdownItem,
    DropdownSeparator,
    DropdownToggle,
    KebabToggle,
    PageHeader,
    PageHeaderTools,
    PageHeaderToolsGroup,
    PageHeaderToolsItem,
} from "@patternfly/react-core";
import {HelpIcon} from "@patternfly/react-icons";
import {ReactNode, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useHelp} from "ui-shared";

import {HelpHeader} from "./components/help-enabler/HelpHeader";
import {useRealm} from "./context/realm-context/RealmContext";
import {useWhoAmI} from "./context/whoami/WhoAmI";
import {toDashboard} from "./dashboard/routes/Dashboard";
import environment from "./environment";
import {keycloak} from "./keycloak";
import {RealmSelector} from "./components/realm-selector/RealmSelector";
import "./page-header.css";
import {LanguageSelector} from "./components/language-selector/LanguageSelector";

const ManageAccountDropdownItem = () => {
    const {t} = useTranslation();
    return (
        <DropdownItem
            style={{
                display: "none",
            }}
            key="manage account"
            id="manage-account"
            onClick={() => keycloak.accountManagement()}
        >
            {t("manageAccount")}
        </DropdownItem>
    );
};

const SignOutDropdownItem = () => {
    const {t} = useTranslation();
    return (
        <DropdownItem
            id="sign-out"
            key="sign out"
            onClick={() => keycloak.logout({redirectUri: ""})}
        >
            {t("signOut")}
        </DropdownItem>
    );
};

const ServerInfoDropdownItem = () => {
    const {realm} = useRealm();
    const {t} = useTranslation();

    return (
        <DropdownItem
            key="server info"
            component={
                // The type definition in PatternFly is incorrect, so we need to cast here.
                ((props: any) => (
                    <Link {...props} to={toDashboard({realm})}/>
                )) as unknown as ReactNode
            }
        >
            {t("realmInfo")}
        </DropdownItem>
    );
};

const HelpDropdownItem = () => {
    const {t} = useTranslation();
    const {enabled, toggleHelp} = useHelp();
    return (
        <DropdownItem icon={<HelpIcon/>} onClick={toggleHelp}>
            {enabled ? t("helpEnabled") : t("helpDisabled")}
        </DropdownItem>
    );
};

const kebabDropdownItems = [
    <ManageAccountDropdownItem key="kebab Manage Account"/>,
    <ServerInfoDropdownItem key="kebab Server Info"/>,
    <HelpDropdownItem key="kebab Help"/>,
    <DropdownSeparator key="kebab sign out separator"/>,
    <SignOutDropdownItem key="kebab Sign out"/>,
];

const userDropdownItems = [
    <ManageAccountDropdownItem key="Manage Account"/>,
    <ServerInfoDropdownItem key="Server info"/>,
    <DropdownSeparator key="sign out separator"/>,
    <SignOutDropdownItem key="Sign out"/>,
];

const KebabDropdown = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    return (
        <Dropdown
            id="user-dropdown-kebab"
            isPlain
            position="right"
            toggle={<KebabToggle onToggle={setDropdownOpen}/>}
            isOpen={isDropdownOpen}
            dropdownItems={kebabDropdownItems}
        />
    );
};

const UserDropdown = () => {
    const {whoAmI} = useWhoAmI();
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    return (
        <Dropdown
            isPlain
            position="right"
            id="user-dropdown"
            isOpen={isDropdownOpen}
            toggle={
                <DropdownToggle onToggle={setDropdownOpen}>
                    {whoAmI.getDisplayName()}
                </DropdownToggle>
            }
            dropdownItems={userDropdownItems}
        />
    );
};

export const Header = () => {
    const {realm} = useRealm();

    const headerTools = () => {
        const picture = keycloak.tokenParsed?.picture;
        return (
            <PageHeaderTools className={"ora-header-tool"}>
                <PageHeaderToolsGroup
                    visibility={{
                        default: "hidden",
                        md: "visible",
                    }} /** the settings and help icon buttons are only visible on desktop sizes and replaced by a kebab dropdown for other sizes */
                >
                    <PageHeaderToolsItem>
                        <LanguageSelector></LanguageSelector>
                    </PageHeaderToolsItem>
                    <PageHeaderToolsItem>
                        <HelpHeader/>
                        <div></div>
                    </PageHeaderToolsItem>
                </PageHeaderToolsGroup>

                <Avatar
                    src={picture || environment.resourceUrl + "/img_avatar.svg"}
                    alt="Avatar image"
                />
                <PageHeaderToolsGroup>
                    <PageHeaderToolsItem
                        visibility={{
                            md: "hidden",
                        }} /** this kebab dropdown replaces the icon buttons and is hidden for desktop sizes */
                    >
                        <KebabDropdown/>
                    </PageHeaderToolsItem>
                    <PageHeaderToolsItem
                        visibility={{
                            default: "hidden",
                            md: "visible",
                        }} /** this user dropdown is hidden on mobile sizes */
                    >
                        <UserDropdown/>
                    </PageHeaderToolsItem>
                </PageHeaderToolsGroup>
            </PageHeaderTools>
        );
    };

    const logo = environment.logo ? environment.logo : "/logo.svg";
    const logoUrl = environment.logoUrl
        ? environment.logoUrl
        : toDashboard({realm});
    const oraNavId = "ora-NavToggleId";
    const clickOraNav = () => {
        document.getElementById(oraNavId)?.click();
    };

    return (
        <PageHeader
            className={"ora-header"}
            showNavToggle
            logo={
                <Link to={logoUrl}>
                    <Brand
                        src={environment.resourceUrl + logo}
                        id="masthead-logo"
                        alt="Logo"
                        className="keycloak__pageheader_brand"
                    />
                </Link>
            }
            logoComponent="div"
            topNav={
                <div className={"ora-menu-top"}>
                    <a className={"ora-btn-toggle"} onClick={clickOraNav}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M6.43198 8.57153C6.11218 8.57153 5.82665 8.69725 5.62106 8.90296L3.33678 11.1887C3.13119 11.4058 3.00555 11.6915 3.00555 12.0001C3.00555 12.3087 3.13119 12.6058 3.33678 12.8115L5.62106 15.0972C5.82665 15.303 6.11218 15.4287 6.43198 15.4287C7.06016 15.4287 7.57413 14.9144 7.57413 14.2858V9.71439C7.57413 9.08582 7.06016 8.57153 6.43198 8.57153V8.57153Z"
                                fill="white"
                            />
                            <path
                                d="M4.14286 6.28571H20.1429C20.7714 6.28571 21.2857 5.77143 21.2857 5.14286C21.2857 4.51429 20.7714 4 20.1429 4H4.14286C3.51429 4 3 4.51429 3 5.14286C3 5.77143 3.51429 6.28571 4.14286 6.28571Z"
                                fill="white"
                            />
                            <path
                                d="M20.1429 17.7144H4.14286C3.51429 17.7144 3 18.2286 3 18.8572C3 19.4858 3.51429 20.0001 4.14286 20.0001H20.1429C20.7714 20.0001 21.2857 19.4858 21.2857 18.8572C21.2857 18.2286 20.7714 17.7144 20.1429 17.7144Z"
                                fill="white"
                            />
                            <rect
                                x="9.85712"
                                y="10.8572"
                                width="11.4286"
                                height="2.28571"
                                rx="1.14286"
                                fill="white"
                            />
                        </svg>
                    </a>
                    <RealmSelector/>
                </div>
            }
            navToggleId={oraNavId}
            headerTools={headerTools()}
        />
    );
};
