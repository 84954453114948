import "@patternfly/react-core/dist/styles/base.css";
import "@patternfly/patternfly/patternfly-addons.css";

import {StrictMode} from "react";
import {createHashRouter, RouterProvider} from "react-router-dom";

import {i18n} from "./i18n/i18n";
import {initKeycloak} from "./keycloak";
import {RootRoute} from "./routes";
import { render } from "react-dom";

import "./index.css";

// Initialize required components before rendering app.
await initKeycloak();
await i18n.init();

const router = createHashRouter([RootRoute]);
const container = document.getElementById("app");

// if (container) {
//     const root = createRoot(container); // createRoot(container!) if you use TypeScript
//     root.render(<StrictMode>
//         <RouterProvider router={router}/>
//     </StrictMode>);
//
// } else {
//     alert("not found element with id app");
// }

render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
  container,
);
