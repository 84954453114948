import React from 'react';
import {Dropdown, DropdownItem, DropdownToggle} from '@patternfly/react-core';
import {useWhoAmI} from "../../context/whoami/WhoAmI";
import {useTranslation} from "react-i18next";
import { ServerInfoRepresentation } from 'libs/keycloak-admin-client/lib/defs/serverInfoRepesentation';

export const LanguageSelector = () => {
    const {whoAmI} = useWhoAmI();
    const [isOpen, setIsOpen] = React.useState(false);

    const onToggle = (isOpen: boolean) => {
        setIsOpen(isOpen);
    };

    const onFocus = () => {
        const element = document.getElementById('toggle-basic');
        element?.focus()
    };

    const onSelect = ($event: any) => {
        setIsOpen(false);
        onFocus();
    };
    const changeLanguage = (lng: string) => {
        whoAmI.changeLocale(lng);
    };
    const getServerInfoIfNeed = (serverInfo: ServerInfoRepresentation) => {
        const {whoAmI} = useWhoAmI();
        if (whoAmI.getLocale() !== "en") {
            const {t} = useTranslation();
            for (const property in serverInfo.componentTypes) {
                const arr = serverInfo.componentTypes[property];
                for (const arrElement of arr) {
                    const arg = "server-info:" + property + ":" + arrElement.id;
                    arrElement.helpText = t(arg, {
                        defaultValue: arrElement.helpText
                    });
                }
            }
            serverInfo.passwordPolicies?.forEach(item => {
                const arg = "server-info:passwordPolicies:" + item.id;
                item.displayName = t(arg, {
                    defaultValue: item.displayName
                });
            })


            return serverInfo;
        }
        return serverInfo;
    }
    const {t} = useTranslation();
    const dropdownItems = [
        <DropdownItem key="link" onClick={() => changeLanguage("vi")}>
            {t("vi")}

        </DropdownItem>,
        <DropdownItem key="link" onClick={() => changeLanguage("en")}>
            {t("en")}
        </DropdownItem>,
    ];

    return (
        <Dropdown
            onSelect={onSelect}
            toggle={
                <DropdownToggle id="toggle-basic" onToggle={onToggle}>
                    {t(whoAmI.getLocale())}
                </DropdownToggle>
            }
            isOpen={isOpen}
            dropdownItems={dropdownItems}
        />
    );
};
