import type {ServerInfoRepresentation} from "@keycloak/keycloak-admin-client/lib/defs/serverInfoRepesentation";
import {PropsWithChildren, useState} from "react";
import {createNamedContext, useRequiredContext} from "ui-shared";

import {adminClient} from "../../admin-client";
import {sortProviders} from "../../util";
import {useFetch} from "../../utils/useFetch";
import {useTranslation} from "react-i18next";
import {forEach} from "lodash-es";
import {useWhoAmI} from "../whoami/WhoAmI";

export const ServerInfoContext = createNamedContext<
    ServerInfoRepresentation | undefined
>("ServerInfoContext", undefined);

export const useServerInfo = () =>  useRequiredContext(ServerInfoContext);

const getServerInfoIfNeed = (serverInfo: ServerInfoRepresentation) => {
    const {whoAmI} = useWhoAmI();
    if (whoAmI.getLocale() !== "en") {
        const {t} = useTranslation();
        for (const property in serverInfo.componentTypes) {
            const arr = serverInfo.componentTypes[property];
            for (const arrElement of arr) {
                const arg = "server-info:" + property + ":" + arrElement.id;
                arrElement.helpText = t(arg, {
                    defaultValue: arrElement.helpText
                });
            }
        }
        serverInfo.passwordPolicies?.forEach(item => {
            const arg = "server-info:passwordPolicies:" + item.id;
            item.displayName = t(arg, {
                defaultValue: item.displayName
            });
        })
        console.log("retủn getServerInfoIfNeed If")
        return serverInfo;
    }
    console.log("retủn getServerInfoIfNeed Else")
    return serverInfo;
}

export const useLoginProviders = () =>
    sortProviders(useServerInfo().providers!["login-protocol"].providers);

export const ServerInfoProvider = ({children}: PropsWithChildren) => {
    const [serverInfo, setServerInfo] = useState<ServerInfoRepresentation>({});
    useFetch(adminClient.serverInfo.find, (data) => {
        // setTimeout(()=>{
        //     for (const property in data.componentTypes) {
        //         const arr = data.componentTypes[property];
        //         for (const arrElement of arr) {
        //             const arg = "server-info:" + property + ":" + arrElement.id;
        //             arrElement.helpText = t(arg, {
        //                 defaultValue: arrElement.helpText
        //             });
        //         }
        //     }
        //     data.passwordPolicies?.forEach(item => {
        //         const arg = "server-info:passwordPolicies:" + item.id;
        //         item.displayName = t(arg, {
        //             defaultValue: item.displayName
        //         });
        //     })
        //     return setServerInfo(data)
        // },1000)
        return setServerInfo(data)
    }, []);

    return (
        <ServerInfoContext.Provider value={serverInfo}>
            {children}
        </ServerInfoContext.Provider>
    );
};
