import Keycloak from "keycloak-js";

import environment from "./environment";

export const keycloak = new Keycloak({
    url: environment.authServerUrl,
    realm: environment.loginRealm,
    clientId: environment.clientId,
});

export async function initKeycloak() {
    // console.log('keycloak', keycloak);
    // keycloak.authServerUrl = "http://localhost:8080";
    const authenticated = await keycloak.init({
        onLoad: "check-sso",
        pkceMethod: "S256",

    });

    // Force the user to login if not authenticated.
    if (!authenticated) {
        await keycloak.login();
    }
}
